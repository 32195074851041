// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rrn-number{
    display:flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 20px;
  gap: 40px;
}
 
.input-group input::placeholder{
    color:#458AD7;
    font-size: 17px;
}

 `, "",{"version":3,"sources":["webpack://./src/Pages/Depositeaccount.css"],"names":[],"mappings":"AAAA;IACI,YAAY;EACd,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;AACX;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".rrn-number{\n    display:flex;\n  align-items: center;\n  margin-left: 30px;\n  margin-top: 20px;\n  gap: 40px;\n}\n \n.input-group input::placeholder{\n    color:#458AD7;\n    font-size: 17px;\n}\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
