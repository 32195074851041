import React, { useEffect, useState } from "react";
import { useSocket } from "./ContextAndHooks/SocketContext";
import "./New.css";

const HistoryTop = () => {
  const socket = useSocket();
  const [history, setHistory] = useState([]);
  const [ndata, setndata] = useState([]);

  useEffect(() => {
    if (history.length > 0) {
      setndata([...history].reverse());
    }
  }, [history]);

  useEffect(() => {
    if (socket) {
      socket.on("lastCrashed", (data) => {
        setHistory(data);
      });
    }
  }, [socket]);

  return (
    <div className="history-top">
      <div className="stats">
        <div className="payouts-wrapper" style={{ height: "96px" }}>
          <div className="payouts-block container" style={{ overflowX: "scroll", direction: "ltr" }}>
            {ndata && ndata.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: '2px 11px',
                  borderRadius: '11px',
                  backgroundColor: '#00000080',
                  color:
                    item < 2
                      ? "rgb(52, 180, 255)"
                      : item <= 10
                      ? "rgb(145, 62, 248)"
                      : "rgb(192, 23, 180)",
                }}
              >
                {item}x
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryTop;

